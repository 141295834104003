import {useMemo} from 'react';
import type {ApolloError} from '@apollo/client';

import type {AltMethodsScenario, Method} from '@core/types/graphql';
import {AltMethodScenarioEnum} from '@core/types/graphql';

import usePaymentQuery from '../../../common/utils/usePaymentQuery';
import ALT_METHODS_SCENARIO_QUERY from '../../../common/graphql/queries/altMethodsScenario.gql';
import type {
  AltMethodsScenarioQuery,
  AltMethodsScenarioQueryVariables,
} from '../../../common/graphql/queries/altMethodsScenario';

type UseAltOneClickResult = {
  loading: boolean;
  isAltOneClick: boolean;
  error?: ApolloError;
};

const useAltOneClick = (paymentMethod: Method): UseAltOneClickResult => {
  const {data, loading, error} = usePaymentQuery<
    AltMethodsScenarioQuery,
    AltMethodsScenarioQueryVariables
  >(ALT_METHODS_SCENARIO_QUERY);

  const isAltOneClick = useMemo(() => {
    const altMethodsScenario: AltMethodsScenario =
      data?.payment?.packagesData?.altMethodsScenario?.find(
        ({method}) => method === paymentMethod,
      );

    return altMethodsScenario?.scenario === AltMethodScenarioEnum.oneClick;
  }, [data, paymentMethod]);

  return {
    loading,
    isAltOneClick,
    error,
  };
};

export default useAltOneClick;
