import {useEffect, useMemo, useRef} from 'react';
import {NetworkStatus, useReactiveVar} from '@apollo/client';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

import URI from '@core/utils/url';
import logger from '@core/logger';
import {Method} from '@core/types/graphql';
import activeMethodTabVar from '@core/graphql/vars/activeMethodTabVar';
import {
  getCachedPurchasedPackageData,
  removeCachedPurchasedPackageData,
} from '@core/payment/utils/cachePurchasedPackageData';
import isPayUrlWithStockId from '@core/utils/url/isPayUrlWithStockId';
import getHistory from '@core/application/utils/getHistory';

import PAYMENT_METHODS, {
  WALLET_METHODS,
} from '../../../common/constants/paymentMethods';
import usePaymentQuery from '../../../common/utils/usePaymentQuery';
import usePaymentProcessingStatus from '../../../common/utils/usePaymentProcessingStatus';
import useFailVia from '../../../common/utils/useFailVia';
import {
  setLoading,
  setReady,
  setInitial,
} from '../../../common/utils/setPaymentProcessingStatus';
import PROCESSING_STATUS from '../../../common/constants/processingStatus';
import usePaymentParams from '../../../common/utils/usePaymentParams';
import PAYMENT_PACKAGES_QUERY from '../graphql/queries/paymentPackages.gql';
import useActivePackage from './useActivePackage';
import PAYMENT_ACTIONS from '../../../common/constants/paymentActions';
import useResetPaymentPackagesCache from './useResetPaymentPackagesCache';
import usePrefetchPaymentDataError from './usePrefetchPaymentDataError';
import getPackageListFromMethodTabs from './getPackageListFromMethodTabs';

/**
 * @desc Get packages from server by provided params, return all available packages
 * @param {Object} variables {action, via, prevVia} - params for packages query
 * @param {String} fetchPolicy - need to customize packages cache params
 * @param {String} errorPolicy
 * @param {Boolean} updateActive - need to update active package after fetch
 * @param {Boolean} needResetCache - need to reset cache after unmount component(@important use when closing pop-up especially)
 * @returns {{defaultActiveTab, error: ApolloError|Undefined, loading: Boolean, packages: Array, activePackage: Object}}
 */
export default function usePaymentPackages({
  fetchPolicy = 'cache-first',
  errorPolicy = 'none',
  updateActive = true,
  needResetCache = false,
} = {}) {
  const {via, action, stockId, viaMethod, packageType} = usePaymentParams();
  const processingStatus = usePaymentProcessingStatus();
  const failVia = useFailVia();
  const {activePackage, setActivePackage} = useActivePackage();
  const packagesRef = useRef([]);
  const defaultActiveTab = useRef('');
  const defaultTabIsChangedAfterDecline = useRef(false);

  const {data, error, networkStatus, loading} = usePaymentQuery(
    PAYMENT_PACKAGES_QUERY,
    {
      fetchPolicy,
      errorPolicy,
      refetchWritePolicy: 'merge',
      // Without this flag refetch method doesn't change 'loading' state and packages is not updated in state
      notifyOnNetworkStatusChange: true,
    },
    {changeViaOnDecline: true, reloadOnDecline: true},
  );

  const packagesData = data?.payment?.packagesData;

  const activeTab =
    useReactiveVar(activeMethodTabVar) || packagesData?.defaultActiveTab;

  const packagesList = useMemo(() => {
    let allPackages = packagesData?.methodTabs
      ? getPackageListFromMethodTabs(packagesData.methodTabs)
      : [];

    // If 'card' and 'midas' are available:
    // - Hide 'card' tab by default and display 'midas' only.
    // - Hide 'midas' and display 'card' if midas failed to load (`PaymentMidasForm` falls back to 'card' in this case).
    const tabs = new Set(allPackages.map(({tabName}) => tabName));
    if (tabs.has(Method.midas) && tabs.has(Method.card)) {
      const hideTab = activeTab === Method.card ? Method.midas : Method.card;
      allPackages = allPackages.filter(({tabName}) => tabName !== hideTab);
    }

    if (
      packageType &&
      action === PAYMENT_ACTIONS.MEMBERSHIP &&
      allPackages.length
    ) {
      const filteredPackagesByType = allPackages.filter(
        ({packageTypes = []}) => {
          return packageTypes.includes(packageType);
        },
      );

      if (filteredPackagesByType.length) {
        return filteredPackagesByType;
      }
    }

    return allPackages;
  }, [packageType, action, packagesData, activeTab]);

  useEffect(() => {
    // Reset processing status on unmount.
    return () => {
      setInitial(true);
    };
  }, []);

  useEffect(() => {
    // Use correct loading network status to avoid problem with data re-fetching
    if (loading) {
      setLoading();
    }
    // Need exactly know that data form query is ready to use and packages are existed
    else if (networkStatus === NetworkStatus.ready && packagesData) {
      setReady();
    }
  }, [packagesData, networkStatus, loading]);

  useEffect(() => {
    if (!via) {
      logger.sendError('[usePaymentPackages] No via in packages request');
    }
  }, [via]);

  useResetPaymentPackagesCache(needResetCache);

  usePrefetchPaymentDataError();

  // Is no active package or current active package doesn't exist in requested package set.
  const matchedPackage = useMemo(() => {
    if (!activePackage) return null;

    return find(packagesList, {
      stockId: activePackage.stockId,
      method: activePackage.method,
    });
  }, [packagesList, activePackage]);

  // init active package
  useEffect(() => {
    if (
      !packagesList.length ||
      processingStatus === PROCESSING_STATUS.LOADING
    ) {
      return;
    }

    /**
     * Set new active package and active tab
     * if defaultActiveTab is changed after decline
     */
    if (defaultTabIsChangedAfterDecline.current) {
      const defaultPackageFromDefaultPaymentMethod = find(packagesList, {
        isDefault: true,
        method: defaultActiveTab.current,
      });

      if (defaultPackageFromDefaultPaymentMethod) {
        setActivePackage(defaultPackageFromDefaultPaymentMethod);

        activeMethodTabVar(defaultPackageFromDefaultPaymentMethod.tabName);

        if (isPayUrlWithStockId()) {
          const payUrl = URI(window.location.pathname + window.location.search);

          if (payUrl.hasSearch('stockId')) {
            payUrl.removeSearch('stockId');
          }

          if (payUrl.hasSearch('viaMethod')) {
            payUrl.removeSearch('viaMethod');
          }

          /**
           * Fail form is hidden when user navigate to first step from second step.
           * Set fail via to url for displaying fail form after active tab
           * is changed and return to first step.
           * @see useCloseFailFormOnNavigation.js
           */
          payUrl.setSearch('via', failVia);

          getHistory().replace(payUrl.toString());
        }
      }

      defaultTabIsChangedAfterDecline.current = false;

      return;
    }

    /**
     * If no valid active package, set active in this order:
     * 1) Package from url (logic of compact pp with 2 steps), if active package already exist
     *    need additionally check payment method (there is packages with same stockId and different method)
     * 2) Default package, if active package already exist need additionally get default package with same method
     * 3) If no package id in url and no default send log and set active first packages from list
     */
    if (!matchedPackage) {
      const defaultPaymentMethod =
        defaultActiveTab.current || PAYMENT_METHODS.CARD;

      const correctViaMethod =
        viaMethod && !Object.values(WALLET_METHODS).includes(viaMethod)
          ? viaMethod
          : null;

      const stockIdPackageFromViaMethod =
        stockId && correctViaMethod
          ? find(packagesList, {
              stockId,
              method: correctViaMethod,
            })
          : null;

      /**
       * Cache the selected package when the user navigates to external payment site
       * and restore the selected package if the user returns.
       * @see cachePurchasedPackageData.js
       */
      const cachedPurchasedPackageData = getCachedPurchasedPackageData();
      const {method, packageId} = cachedPurchasedPackageData || {};

      const stockIdPackageFromCache =
        packageId && !stockId && method === correctViaMethod
          ? find(packagesList, {
              stockId: packageId,
              method: correctViaMethod,
            })
          : null;

      if (cachedPurchasedPackageData) {
        removeCachedPurchasedPackageData();
      }

      const defaultPackageFromViaMethod = correctViaMethod
        ? find(packagesList, {
            isDefault: true,
            method: correctViaMethod,
          })
        : null;

      const stockIdPackageFromDefaultPaymentMethod = stockId
        ? find(packagesList, {
            stockId,
            method: defaultPaymentMethod,
          })
        : null;

      const defaultPackageFromDefaultPaymentMethod = find(packagesList, {
        isDefault: true,
        method: defaultPaymentMethod,
      });

      const defaultPackage = find(packagesList, 'isDefault');

      if (!defaultPackage && !packageType) {
        logger.sendWarning(`[usePaymentPackages] There is no default package`);
      }

      const newActivePackage =
        stockIdPackageFromViaMethod ||
        stockIdPackageFromCache ||
        defaultPackageFromViaMethod ||
        stockIdPackageFromDefaultPaymentMethod ||
        defaultPackageFromDefaultPaymentMethod ||
        packagesList[0];

      if (updateActive) {
        setActivePackage(newActivePackage);
      }

      activeMethodTabVar(newActivePackage.tabName);
    }
    /**
     * Or if during a deep check, the active package does not match the package from the newest package list, this can happen
     * if the stockId is the same but the package price differs due to an expired discount.
     */
    if (matchedPackage && !isEqual(activePackage, matchedPackage)) {
      const id = matchedPackage.stockId;
      const relevantPackage = id
        ? find(packagesList, {
            stockId: id,
            method: matchedPackage.method,
          })
        : null;

      if (relevantPackage) {
        setActivePackage(relevantPackage);
      }
    }
  }, [
    packageType,
    processingStatus,
    packagesList,
    setActivePackage,
    stockId,
    viaMethod,
    updateActive,
    failVia,
    matchedPackage,
    activePackage,
  ]);

  let packages = packagesRef.current;

  // To prevent erasing packages when reloading them
  if (packagesData && !loading) {
    packages = packagesList;
    packagesRef.current = packagesList;

    /**
     * Set new active package and active tab
     * if defaultActiveTab is changed after decline
     */
    if (
      failVia &&
      defaultActiveTab.current &&
      defaultActiveTab.current !== packagesData.defaultActiveTab
    ) {
      defaultTabIsChangedAfterDecline.current = true;
    }

    defaultActiveTab.current = packagesData.defaultActiveTab;
  }

  return useMemo(() => {
    return {
      error,
      loading,
      activePackage,
      packages,
      // need for usePaymentTabs
      defaultActiveTab: defaultActiveTab.current,
    };
  }, [activePackage, error, loading, packages]);
}
