import type {ApolloError} from '@apollo/client';

import usePaymentQuery from '../../../common/utils/usePaymentQuery';
import type {
  PaymentBillingPolicyQuery,
  PaymentBillingPolicyQueryVariables,
} from '../graphql/queries/paymentBillingPolicy';
import PAYMENT_BILLING_POLICY_QUERY from '../graphql/queries/paymentBillingPolicy.gql';
import type {TextBillingPolicy} from '../types';

type PaymentBillingPolicyResult = {
  data: TextBillingPolicy | null;
  error: ApolloError | null;
  loading: boolean;
};

/**
 * @desc Hook for getting payment billing policy data
 */
const usePaymentBillingPolicy = (): PaymentBillingPolicyResult => {
  const {data, loading, error} = usePaymentQuery<
    PaymentBillingPolicyQuery,
    PaymentBillingPolicyQueryVariables
  >(PAYMENT_BILLING_POLICY_QUERY);

  if (loading || error || !data || !data.payment.paymentFooter) {
    return {
      data: null,
      error,
      loading,
    };
  }

  const {textBillingPolicy, textBillingPolicyForTargetSite} =
    data.payment.paymentFooter.blockDataMap;

  return {
    data: textBillingPolicy.translateMessageEntityMap.billingPolicyText
      ? textBillingPolicy
      : textBillingPolicyForTargetSite,
    loading,
    error,
  };
};

export default usePaymentBillingPolicy;
