import type {ApolloError} from '@apollo/client';

import usePaymentQuery from '../../../common/utils/usePaymentQuery';
import PAYMENT_DNSMPI_QUERY from '../graphql/queries/paymentDNSMPI.gql';
import type {
  PaymentDnsmpiQuery,
  PaymentDnsmpiQueryVariables,
} from '../graphql/queries/paymentDNSMPI';

type UsePaymentDNSMPIResult = {
  dnsmpiLink: string;
  error?: ApolloError;
  loading: boolean;
};

const usePaymentDNSMPI = (): UsePaymentDNSMPIResult => {
  const {data, error, loading} = usePaymentQuery<
    PaymentDnsmpiQuery,
    PaymentDnsmpiQueryVariables
  >(PAYMENT_DNSMPI_QUERY);

  if (loading || error) {
    return {
      dnsmpiLink: null,
      error,
      loading,
    };
  }

  return {
    dnsmpiLink: data?.payment?.paymentFooter?.blockDataMap?.dnsmpiLink || null,
    error,
    loading,
  };
};

export default usePaymentDNSMPI;
