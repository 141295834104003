import {getClientInstance} from '@core/graphql/client';

const resetPaymentPackagesCache = (broadcast = true) => {
  getClientInstance().cache.evict({
    id: 'Payment:{}',
    fieldName: 'packagesData',
    broadcast,
  });
};

export default resetPaymentPackagesCache;
