import PAYMENT_TEMPLATE_SETTINGS_QUERY from '../graphql/queries/paymentTemplateSettings.gql';
import useRestApiPaymentTemplateSettings from './useRestApiPaymentTemplateSettings';
import usePaymentQuery from './usePaymentQuery';

/**
 * @desc Hook notices about position components on the payment page
 *       (in footer if is not allowed or under card form if is allowed)
 * @return {Object}
 */
const useIsComplianceFriendlyAllowed = () => {
  const {
    isComplianceFriendly,
    isAllowed,
    loading: restApiTemplateSettingsLoading,
  } = useRestApiPaymentTemplateSettings();

  const skip = isAllowed || restApiTemplateSettingsLoading;

  const {data, loading: templateSettingsLoading} = usePaymentQuery(
    PAYMENT_TEMPLATE_SETTINGS_QUERY,
    {
      skip,
    },
  );

  const loading = restApiTemplateSettingsLoading || templateSettingsLoading;

  if (isAllowed) {
    return {
      loading,
      isComplianceFriendly,
    };
  }

  return {
    isComplianceFriendly:
      data?.payment?.templateSettings?.templateSettingsData
        ?.isComplianceFriendly || false,
    loading,
  };
};

export default useIsComplianceFriendlyAllowed;
