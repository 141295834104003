import {useEffect} from 'react';

import logger from '@core/logger';

import PROCESSING_STATUS from '../../../common/constants/processingStatus';
import useFailVia from '../../../common/utils/useFailVia';
import usePaymentData from '../../../common/utils/usePaymentData';
import usePaymentProcessingStatus from '../../../common/utils/usePaymentProcessingStatus';

const usePrefetchPaymentDataError = () => {
  const {isEnabled} = usePaymentData();

  const failVia = useFailVia();

  const processingStatus = usePaymentProcessingStatus();

  useEffect(() => {
    if (
      isEnabled &&
      processingStatus === PROCESSING_STATUS.LOADING &&
      !failVia
    ) {
      logger.sendWarning(
        '[usePrefetchPaymentDataError] Something went wrong. PaymentBaseDataQuery should prefetch packages for this case',
      );
    }
  }, [failVia, processingStatus, isEnabled]);
};

export default usePrefetchPaymentDataError;
