import React, {useMemo, useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import isFunction from 'lodash/isFunction';

import {getClientInstance} from '@core/graphql/client';
import isPayUrl from '@core/utils/url/isPayUrl';
import isPaySuccessUrl from '@core/utils/url/isPaySuccessUrl';
import logger from '@core/logger';
import getHistory from '@core/application/utils/getHistory';

import usePaymentParams from '../utils/usePaymentParams';
import PAYMENT_ACTIONS, {
  LEGACY_BACKBONE_ACTIONS,
} from '../constants/paymentActions';
import isEnabledReactPaymentPage from '../utils/isEnabledReactPaymentPage';
import PaymentPageSuccessOrders from '../../payProcess/utils/PaymentPageSuccessOrders';
import PaymentDataContext from './PaymentDataContext';
import type {
  PaymentBaseDataQuery,
  PaymentBaseDataQueryVariables,
} from '../../pages/graphql/queries/paymentBaseData';
import PAYMENT_BASE_DATA_QUERY from '../../pages/graphql/queries/paymentBaseData.gql';
import {getErrorRedirect} from '../utils/useValidateVia';

/**
 * Excluded payment routes. Skip preload payment data on selected routes.
 */
const excludePayRoutes = [
  '/pay/appFunnel/newOffer',
  '/pay/appFunnel/getTheApp',
  '/pay/retryPermission',
];

const PaymentDataProvider = ({children, isReact, processExternalPay}) => {
  const {pathname} = useLocation();

  const params = usePaymentParams();

  const {
    action,
    prevVia,
    via,
    originalAction,
    source,
    is3dSecureSuccess,
    threeDSecureComplete,
  } = params;

  const isEnabled = useMemo(() => {
    if (
      !isPayUrl(pathname) ||
      isPaySuccessUrl(pathname) ||
      excludePayRoutes.some((route) => pathname.startsWith(route)) ||
      LEGACY_BACKBONE_ACTIONS.includes(action)
    ) {
      return false;
    }

    if (pathname.includes('pay/alternative')) {
      logger.sendError('[PaymentBaseDataLoader] Buggy pay/alternative action', {
        skipPercentageCheck: true,
      });

      return false;
    }

    if (isReact || isEnabledReactPaymentPage()) {
      return true;
    }

    /**
     * Some payment actions are using react payment page by default without cookie
     */
    return (
      PAYMENT_ACTIONS.MESSAGE === action ||
      [PAYMENT_ACTIONS.APP_FUNNEL, PAYMENT_ACTIONS.IFRAME_PP].includes(
        originalAction,
      )
    );
  }, [pathname, action, originalAction, isReact]);

  const [loading, setLoading] = useState(isEnabled);

  const [error, setError] = useState(null);

  const [data, setData] = useState(null);

  const variables = useMemo(() => {
    return {
      action,
      source,
      orderIds: PaymentPageSuccessOrders.getIds(),
      prevVia,
      via,
    };
  }, [action, via, prevVia, source]);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    setLoading(true);

    getClientInstance()
      .query<PaymentBaseDataQuery, PaymentBaseDataQueryVariables>({
        query: PAYMENT_BASE_DATA_QUERY,
        variables,
      })
      .then(async (result) => {
        setData(result.data);

        if (threeDSecureComplete && isFunction(processExternalPay)) {
          await processExternalPay(variables.action, is3dSecureSuccess);
        }
      })
      .catch((paymentDataError) => {
        setError(paymentDataError);

        logger.sendError(
          `[PaymentDataProvider] No initial data for payment page ${error}`,
        );

        const errorRedirectUrl = getErrorRedirect({error, via: variables.via});

        // Redirect to via=unknown if received invalid via
        if (errorRedirectUrl) {
          getHistory().replace(errorRedirectUrl);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    isEnabled,
    variables,
    threeDSecureComplete,
    processExternalPay,
    is3dSecureSuccess,
    error,
  ]);

  return (
    <PaymentDataContext.Provider
      value={{
        data,
        loading,
        error,
        isEnabled: isEnabled && !error,
      }}
    >
      {children}
    </PaymentDataContext.Provider>
  );
};

export default PaymentDataProvider;
