import {useContext} from 'react';

import PaymentDataContext from '../containers/PaymentDataContext';

const usePaymentData = () => {
  const {data, loading, error, isEnabled} = useContext(PaymentDataContext);

  return {
    data,
    loading,
    error,
    isEnabled,
  };
};

export default usePaymentData;
