import type {FC, HTMLAttributes, ReactNode} from 'react';
import React from 'react';
import cn from 'classnames';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';
import createRouteName from '@core/tracking/babcia/utils/createRouteName';
import isDeviceWithTouchScreen from '@core/utils/device/isDeviceWithTouchScreen';

import css from './Link.css';

export enum LinkTargetType {
  BLANK = '_blank',
  SELF = '_self',
}

export type LinkProps = HTMLAttributes<HTMLAnchorElement> & {
  children?: ReactNode;
  className?: string;
  unstyled?: boolean;
  small?: boolean;
  inverse?: boolean;
  muted?: boolean;
  inherit?: boolean;
  href?: string;
  target?: LinkTargetType;
  inline?: boolean;
  underline?: boolean;
  trackingName?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

/**
 * @class Link
 * @classdesc Anchor link component
 * @todo Add option to connect this component to react-router
 */
const Link: FC<LinkProps> = ({
  children,
  className,
  unstyled,
  small = false,
  inverse = false,
  muted = false,
  inherit = false,
  href,
  target = LinkTargetType.SELF,
  inline = false,
  underline,
  trackingName,
  onClick,
  ...otherProps
}) => {
  const props: {
    href?: string;
    target?: LinkTargetType;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  } = {
    className: cn(
      !unstyled && css.link,
      small && css.small,
      inverse && css.inverse,
      muted && css.muted,
      inherit && css.inherit,
      inline && css.inline,
      !isDeviceWithTouchScreen && css.hoverable,
      underline && css.underline,
      className,
    ),
    onClick,
    ...otherProps, // There are 'data' attrs that can be passed down
  };

  if (href) {
    props.href = href;
    props.target = target;
  }

  // @todo Use react-router <Link> component instead
  const link = <a {...props}>{children}</a>;

  if (trackingName || href) {
    return (
      <AddBabciaUBTracking
        trackingName={`${trackingName || createRouteName(href)}Link`}
      >
        {link}
      </AddBabciaUBTracking>
    );
  }

  return link;
};

export default Link;
