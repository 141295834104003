import type {ApolloCache} from '@apollo/client';

import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';

/**
 * Invalidate cache for renewing search banners and flirtcast banner.
 */
const clearSearchBannersCache = (
  client: {cache: ApolloCache<object>},
  broadcast = true,
) => {
  // Invalidate caches when entering payment page
  // Clear banners cache
  invalidateCacheByTypename(client, 'Banners', {broadcast});
  invalidateCacheByTypename(client, 'Remarketing', {
    fieldName: 'banner',
    broadcast,
  });

  // Clear flirtcast data
  invalidateCacheByTypename(client, 'MotivationBanner', {broadcast});
  invalidateCacheByTypename(client, 'Flirtcast', {broadcast});

  invalidateCacheByTypename(client, 'UserFeatures', {
    fieldName: 'extraPaymentBanner',
    broadcast,
  });
  invalidateCacheByTypename(client, 'ExtraPaymentBanner', {broadcast});
  invalidateCacheByTypename(client, 'Notifications', {broadcast});
};

export default clearSearchBannersCache;
