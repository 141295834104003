import find from 'lodash/find';

import PAYMENT_METHODS from '../constants/paymentMethods';
import ALT_METHODS_SETTINGS_QUERY from '../graphql/queries/altMethodsSettings.gql';
import usePaymentQuery from './usePaymentQuery';

const ALT_METHOD_SETTINGS_MAP = {
  [PAYMENT_METHODS.PAYGARDEN]: 'PaygardenSettings',
  [PAYMENT_METHODS.APPLE_PAY]: 'ApplePaySettings',
  [PAYMENT_METHODS.GOOGLE_PAY]: 'GooglePaySettings',
  [PAYMENT_METHODS.COMM_DOO_SEPA]: 'CommDooSepaSettings',
  [PAYMENT_METHODS.TRUST_PAY_IDEAL]: 'TrustPayIdealSettings',
  [PAYMENT_METHODS.NUVEI_IDEAL]: 'NuveiIdealSettings',
  [PAYMENT_METHODS.NOVAL_NET_SEPA]: 'NovalNetSepaSettings',
  [PAYMENT_METHODS.VENDO_SEPA]: 'VendoSepaSettings',
  [PAYMENT_METHODS.TRUST_PAY_SEPA]: 'TrustPaySepaSettings',
  [PAYMENT_METHODS.COMM_DOO_SEPA]: 'CommDooSepaSettings',
  [PAYMENT_METHODS.MICRO_PAYMENT_SEPA]: 'MicropaymentSepaSettings',
  [PAYMENT_METHODS.PAY_PAL_V2]: 'Paypalv2Settings',
  [PAYMENT_METHODS.SOFORT]: 'SofortSettings',
  [PAYMENT_METHODS.NUVEI_MY_BANK]: 'NuveiMyBankSettings',
  [PAYMENT_METHODS.KONBINI]: 'KonbiniSettings',
  [PAYMENT_METHODS.SALT_EDGE_AIS]: 'SaltEdgeAisSettings',
};

export const getSettingsByMethod = (altMethodsSettings, method) => {
  if (!method || !altMethodsSettings) {
    return null;
  }

  return (
    find(altMethodsSettings, {
      __typename: ALT_METHOD_SETTINGS_MAP[method],
    }) || null
  );
};

const useAltMethodsSettings = ({paymentMethod, skip = false}) => {
  const {data, loading, error} = usePaymentQuery(
    ALT_METHODS_SETTINGS_QUERY,
    {
      skip,
    },
    {
      changeViaOnDecline: true,
    },
  );

  const settings = getSettingsByMethod(
    data?.payment.packagesData.altMethodsSettings,
    paymentMethod,
  );

  return {settings, loading, error};
};

export default useAltMethodsSettings;
