import {useQuery} from '@apollo/client';
import {useLocation} from 'react-router-dom';

import isPaymentUrl from '@core/utils/url/isPayUrl';
import useEventCallback from '@core/utils/react/useEventCallback';
import logger from '@core/logger';
import isAllowedPaymentMethod from '@core/payment/common/utils/isAllowedPaymentMethod';

import useIsMobFirstStage from '../../../common/utils/useIsMobFirstStage';
import usePaymentParams from '../../../common/utils/usePaymentParams';
import usePaymentQuery from '../../../common/utils/usePaymentQuery';
import useActivePackage from '../../package/utils/useActivePackage';
import usePaymentPackageDescriptionRule from '../../packageDescription/utils/usePaymentPackageDescriptionRule';
import PAYMENT_ADDITIONAL_TERMS_QUERY from '../graphql/queries/paymentAdditionalTerms.gql';
import PAYMENT_ADDITIONAL_TERMS_CHECKED_QUERY from '../graphql/queries/paymentAdditionalTermsChecked.gql';
import resetAdditionalTermsChecked from './resetAdditionalTermsChecked';

/**
 * @desc Hook for set and check flag if additional terms is checked
 * @return {Object} obj
 * @return {Boolean} obj.isPayUrl
 * @return {Boolean} obj.isAdditionalTermsChecked
 * @return {Function} obj.setIsAdditionalTermsChecked
 * @return {Function} obj.isAdditionalTermsNeeded
 * @return {Function} obj.isBillingPolicy
 */
const useAdditionalTerms = () => {
  const isMobFirstStage = useIsMobFirstStage();
  const {pathname} = useLocation();
  const {activePackage} = useActivePackage();
  const {action} = usePaymentParams();
  const {loading: withBillingPolicyLoading, isWithBillingPolicy} =
    usePaymentPackageDescriptionRule();

  const {
    data: templateData,
    loading,
    error,
  } = usePaymentQuery(PAYMENT_ADDITIONAL_TERMS_QUERY);

  const {
    data: {
      payment: {isAdditionalTermsChecked},
    },
  } = useQuery(PAYMENT_ADDITIONAL_TERMS_CHECKED_QUERY);

  const setIsAdditionalTermsChecked = useEventCallback((checked) => {
    if (checked === isAdditionalTermsChecked) {
      return;
    }

    resetAdditionalTermsChecked(checked);
  });

  if (loading || withBillingPolicyLoading || error) {
    return {
      withCheckbox: false,
      isAdditionalTermsChecked: false,
      isAdditionalTermsNeeded: false,
      billingPolicyDefaultValue: null,
      setIsAdditionalTermsChecked,
      loading,
      error,
    };
  }

  const {additionalTerms, additionalTermsStatusMap} =
    templateData?.payment?.templateSettings || {};

  const billingPolicy = additionalTermsStatusMap?.billingPolicy;
  const agreeAndContinue = additionalTermsStatusMap?.agreeAndContinue;
  const autoRenewalAgreeXSale = additionalTerms?.autoRenewalAgreeXSale;

  let billingPolicyDefaultValue = null;

  if (billingPolicy !== null) {
    billingPolicyDefaultValue = billingPolicy;
  }

  if (agreeAndContinue !== null) {
    billingPolicyDefaultValue = agreeAndContinue;
  }

  const isPayUrl = isPaymentUrl(pathname);

  const isAdditionalTerms =
    isPayUrl &&
    billingPolicyDefaultValue !== null &&
    isAllowedPaymentMethod(activePackage, action);

  if (isAdditionalTerms && isWithBillingPolicy) {
    logger.sendWarning(
      `[PaymentTemplateSettingsQuery] Footer part "PackageDescriptionWithBillingPolicy" doesn't support additional terms "billingPolicy" and "agreeAndContinue"`,
    );
  }

  return {
    isPayUrl,
    withCheckbox: isAdditionalTerms && !isMobFirstStage && !isWithBillingPolicy,
    setIsAdditionalTermsChecked,
    isAdditionalTermsChecked,
    isAdditionalTermsNeeded: isAdditionalTerms && !isWithBillingPolicy,
    billingPolicyDefaultValue,
    autoRenewalAgreeXSale,
    loading,
    error,
  };
};

export default useAdditionalTerms;
