import {createContext} from 'react';
import type {Context} from 'react';
import type {ApolloError} from '@apollo/client';

import type {PaymentBaseDataQuery} from '../../pages/graphql/queries/paymentBaseData';

const defaultValue = {
  data: null,
  loading: false,
  error: null,
  isEnabled: false,
};

const PaymentDataContext: Context<{
  data: PaymentBaseDataQuery | null;
  loading: boolean;
  error: ApolloError | null;
  isEnabled: boolean;
}> = createContext(defaultValue);

export default PaymentDataContext;
