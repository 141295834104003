import {useEffect, useMemo} from 'react';
import type {
  DocumentNode,
  TypedDocumentNode,
  QueryHookOptions,
} from '@apollo/client';
import {useQuery} from '@apollo/client';
import {
  getOperationName,
  getOperationDefinition,
} from '@apollo/client/utilities';
import type {VariableDefinitionNode} from 'graphql';

import usePaymentParams from '@core/payment/common/utils/usePaymentParams';
import usePaymentData from '@core/payment/common/utils/usePaymentData';
import useFailVia from '@core/payment/common/utils/useFailVia';
import type {PaymentBaseDataQueryVariables} from '@core/payment/pages/graphql/queries/paymentBaseData';
import {INPAGE_DECLINE_VIAS} from '@core/payment/common/constants/declineVia';
import type {ViaEnum} from '@core/types';
import usePaymentProcessingStatus from '@core/payment/common/utils/usePaymentProcessingStatus';
import PROCESSING_STATUS from '@core/payment/common/constants/processingStatus';
import PaymentPageSuccessOrders from '@core/payment/payProcess/utils/PaymentPageSuccessOrders';

/**
 * Extracts only the required variables for a GraphQL query
 * @param variables - All available variables
 * @param query - The GraphQL query document
 * @returns An object containing only the variables required by the query
 */
const getRequiredVariables = (
  variables: Partial<PaymentBaseDataQueryVariables>,
  query: DocumentNode,
): Partial<PaymentBaseDataQueryVariables> => {
  const operationDefinition = getOperationDefinition(query);

  if (!operationDefinition || !operationDefinition.variableDefinitions) {
    return variables;
  }

  const requiredVariableNames = operationDefinition.variableDefinitions.map(
    (varDef: VariableDefinitionNode) => varDef.variable.name.value,
  );

  const requiredVariables = {};

  requiredVariableNames.forEach((variable) => {
    if (Object.prototype.hasOwnProperty.call(variables, variable)) {
      requiredVariables[variable] = variables[variable];
    }
  });

  return requiredVariables;
};

const QUERIES = [
  'PaymentMethodTabsQuery',
  'AltMethodsSettingsQuery',
  'AltMethodsScenarioQuery',
];

/**
 * Prevents duplicate execution of specific GraphQL queries that may be
 * affected by batching and result merging issues.
 *
 * After a payment decline, multiple queries need to refetch:
 * PaymentPackagesQuery, PaymentMethodTabsQuery, AltMethodsSettingsQuery, and AltMethodsScenarioQuery
 * are executed within the same batch and incorrectly merged, overwriting each other's results.
 *
 * This leads to inconsistent data states and triggers unnecessary additional requests.
 * To prevent this, the function restricts duplicate calls for the specified queries,
 * ensuring data consistency and reducing redundant fetches.
 *
 * In this case, data will be preloaded by PaymentPackagesQuery and
 * was taken from cache by other queries.
 */
const needPreventDuplicateCalls = (operationName: string) => {
  return QUERIES.includes(operationName);
};

type Settings = {
  /**
   * Replaces `via` with `failVia` when a payment is declined.
   * Used for:
   *  - Insufficient funds - PaymentPackagesQuery
   *  - Payment button titles - PaymentButtonTitleQuery
   *  - Payment methods - PaymentMethodTabsQuery
   *  - Alt method scenarios/settings - AltMethodsScenarioQuery | AltMethodsSettingsQuery
   */
  changeViaOnDecline?: boolean;

  /**
   * Triggers a reload query whenever a payment is declined.
   * Used for:
   *  - Long form fields - PaymentLongFormFieldsQuery
   *  - One-click - OneClickParamsQuery | IsOneClickAllowedQuery
   *  - Insufficient funds - PaymentPackagesQuery
   */
  reloadOnDecline?: boolean;
};

const usePaymentQuery = <TData, TVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  queryOptions?: QueryHookOptions<TData, TVariables>,
  settings: Settings = {
    changeViaOnDecline: false,
    reloadOnDecline: false,
  },
) => {
  const {isEnabled, loading: baseDataLoading} = usePaymentData();

  const {changeViaOnDecline, reloadOnDecline} = settings;

  const {variables: queryVariables, fetchPolicy: queryFetchPolicy} =
    queryOptions || {};

  /**
   * Payment params is provided by PaymentParamsProvider.
   * @see LocationPaymentParamsProvider
   * @see withStaticPaymentParamsProvider
   */
  const {via, prevVia, action, source} = usePaymentParams();

  const processingStatus = usePaymentProcessingStatus();

  const failVia = useFailVia() as ViaEnum;

  const isFailedPayment = processingStatus === PROCESSING_STATUS.FAILED;

  const variables = useMemo(() => {
    let params = {
      action,
      prevVia,
      via,
      source,
      orderIds: PaymentPageSuccessOrders.getIds(),
    };

    if (queryVariables) {
      params = {
        ...params,
        ...queryVariables,
      };
    }

    if (failVia && changeViaOnDecline) {
      params.via = failVia;
      params.prevVia = !INPAGE_DECLINE_VIAS.includes(via) ? via : prevVia;
    }

    return getRequiredVariables(params, query);
  }, [
    query,
    action,
    prevVia,
    via,
    source,
    queryVariables,
    changeViaOnDecline,
    failVia,
  ]);

  const operationName = useMemo(() => {
    return getOperationName(query);
  }, [query]);

  const fetchPolicy = useMemo(() => {
    if (isEnabled && needPreventDuplicateCalls(operationName)) {
      return 'cache-only';
    }

    return queryFetchPolicy;
  }, [operationName, queryFetchPolicy, isEnabled]);

  const {
    refetch,
    data,
    loading: queryLoading,
    networkStatus,
    error,
    ...queryData
  } = useQuery<TData, Partial<PaymentBaseDataQueryVariables>>(query, {
    ...queryOptions,
    skip: baseDataLoading || queryOptions?.skip,
    variables,
    fetchPolicy,
  });

  /**
   * Refetch query on decline
   */
  useEffect(() => {
    if (reloadOnDecline && isFailedPayment && failVia) {
      refetch();
    }
  }, [failVia, isFailedPayment, reloadOnDecline, refetch]);

  return {
    refetch,
    data,
    loading: reloadOnDecline ? queryLoading && !data : queryLoading,
    networkStatus,
    error,
    ...queryData,
  };
};

export default usePaymentQuery;
