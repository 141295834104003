import usePaymentQuery from '../../../common/utils/usePaymentQuery';
import PAYMENT_PACKAGE_DESCRIPTION_QUERY from '../graphql/queries/paymentPackageDescription.gql';
import type {
  PaymentPackageDescriptionQuery,
  PaymentPackageDescriptionQueryVariables,
} from '../graphql/queries/paymentPackageDescription';

type UsePaymentPackageDescriptionResult = {
  loading: boolean;
  packageDescriptions: PaymentPackageDescriptionQuery['payment']['paymentFooter']['blockDataMap']['packageDescriptions'];
  strictLegalMode: boolean;
};

const usePaymentPackageDescription = (): UsePaymentPackageDescriptionResult => {
  const {data, loading} = usePaymentQuery<
    PaymentPackageDescriptionQuery,
    PaymentPackageDescriptionQueryVariables
  >(PAYMENT_PACKAGE_DESCRIPTION_QUERY);

  if (loading) {
    return {
      loading,
      packageDescriptions: [],
      strictLegalMode: false,
    };
  }

  return {
    loading,
    packageDescriptions:
      data?.payment?.paymentFooter?.blockDataMap?.packageDescriptions || [],
    // Need for set black text FE-12065
    strictLegalMode:
      data?.payment?.paymentFooter?.displaySettingData?.strictLegalMode ||
      false,
  };
};

export default usePaymentPackageDescription;
