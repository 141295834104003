import {useRef} from 'react';

import usePaymentQuery from '../../../common/utils/usePaymentQuery';
import getPackageListFromMethodTabs from './getPackageListFromMethodTabs';
import PAYMENT_PACKAGES_QUERY from '../graphql/queries/paymentPackages.gql';

/**
 * WARNING: USE ONLY ON PAYMENT PAGE!
 * Hook for receiving cached payment packages that were received earlier by usePaymentPackages hook.
 * useQuery loading flag not working correctly with 'cache-only' fetch policy.
 * @see usePaymentPackages.js
 * @return {{packages: Array, error: ApolloError, loading: boolean}}
 * Props drilling hotfix. Need get packages inside usePaymentMotivation hook, because BU can`t implement discount value correctly.
 * TODO: Need remove after BU side fix discount.
 * @deprecated
 */
const useCachedPaymentPackages = () => {
  const {data, error, loading} = usePaymentQuery(
    PAYMENT_PACKAGES_QUERY,
    {
      fetchPolicy: 'cache-only',
    },
    {
      changeViaOnDecline: true,
    },
  );

  const packages = useRef([]);

  const packagesData = data?.payment?.packagesData;

  if (packagesData && !loading) {
    packages.current = getPackageListFromMethodTabs(packagesData.methodTabs);
  }

  return {
    packages: packages.current,
    error,
    loading,
  };
};

export default useCachedPaymentPackages;
